<template>
    <auth-layout>
    </auth-layout>
</template>
<script>
    import {mapActions} from 'vuex';
    import AuthLayout from '@/layouts/AuthLayout';
    import Helper from "../includes/Helper";
    export default {
        components: {
            'auth-layout': AuthLayout
        },
        methods: {
            ...mapActions('User', ['logout']),
            back(){
                window.history.back();
            }
        },
        created(){
            Helper.showSpinner();
            this.logout();
        }
    }
</script>
